import Link from 'next/link';
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Header() {
  return (
    <div>
      <Container fixed="top">

        <Navbar expand="lg" id="light-header" className="justify-content-between">

          <Container>
            <Link href="/" legacyBehavior>
              <Navbar.Brand href="/">BIG & MINI</Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse className="justify-content-end">
              <Nav>

                <Nav.Item id="dropdown-title">
                  <Link href="/about" legacyBehavior>
                    <Nav.Link href="/about">About</Nav.Link>
                  </Link>
                  <div className="dropdown-links">
                    <Link href="/about" legacyBehavior>
                      <Nav.Link href="/about">Our Story</Nav.Link>
                    </Link>
                    {/* <Nav.Link href = "/teampage">Our Team</Nav.Link> */}
                    <Link href="/whatsnew" legacyBehavior>
                      <Nav.Link href="/whatsnew">What&apos;s New</Nav.Link>
                    </Link>
                  </div>
                </Nav.Item>

                <Nav.Item id="dropdown-title">
                  <Link href="/howitworks" legacyBehavior>
                    <Nav.Link href="/howitworks">How It Works</Nav.Link>
                  </Link>
                  <div className="dropdown-links">
                    <Link href="/howitworks" legacyBehavior>
                      <Nav.Link href="/howitworks">For Individuals</Nav.Link>
                    </Link>
                    {/* <Nav.Link href = "orgpage">For Organizations</Nav.Link> */}
                    <Link href="/famsite" legacyBehavior>
                      <Nav.Link href="/famsite">FAM Program</Nav.Link>
                    </Link>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <Link href="/orgpage" legacyBehavior>
                    <Nav.Link href="/orgpage" id="hoverable-link">Organizations</Nav.Link>
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link href="/login" legacyBehavior>
                    <Nav.Link href="/login" id="hoverable-link">Log In</Nav.Link>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="donate-button" href="https://www.classy.org/give/282981/#!/donation/checkout">
                    Donate
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id="mobile-button">
                  <Link href="/signup" legacyBehavior>
                    <Nav.Link className="signup-button" href="/signup">
                      Sign Up
                    </Nav.Link>
                  </Link>
                </Nav.Item>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </Container>

    </div>
  );
}

export default Header;
